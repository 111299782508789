<template>
  <div class="container">
    <div class="userinfo">
      <router-link to="/edit">
        <img src="../assets/img/icon-edit.png" alt="完善资料" />
        <p>完善资料</p>
      </router-link>
      <div class="avatar"><img :src="avatarUrl" :title="nickName" :alt="nickName" /></div>
      <div class="info">
        <h1>{{nickName}}</h1>
        <p>{{phoneNumber}}</p>
      </div>
      <!-- <a href="/#/edit">
        <img src="../assets/img/icon-edit.png" alt="完善资料" />
        <p>完善资料</p>
      </a> -->
    </div>
    <div class="menu">
      <div class="box">
        <router-link to="/orderList"><img src="../assets/img/icon-order.png" alt="我的订单" />我的订单</router-link>
        <!-- 2022.11.23 冰雪节项目注释这部分 新加消费劵 -->
        <router-link to="/skiCouponList"><img src="../assets/img/icon-integral.png" alt="我的评论" />我的消费劵</router-link>
        <!-- <router-link to="/comment"><img src="../assets/img/icon-comment.png" alt="我的评论" />我的评论</router-link>
        <router-link to="/collection"><img src="../assets/img/icon-collection.png" alt="我的收藏" />我的收藏</router-link>
        <router-link to="/message"><img src="../assets/img/icon-message.png" alt="我的消息" />我的消息</router-link>
        <router-link to="/integral"><img src="../assets/img/icon-integral.png" alt="我的积分" />我的积分</router-link> -->
        <!-- 2022.11.23 冰雪节项目 -->
        <!-- <a href="/#/orderList"><img src="../assets/img/icon-order.png" alt="我的订单" />我的订单</a>
        <a href="/#/comment"><img src="../assets/img/icon-comment.png" alt="我的评论" />我的评论</a>
        <a href="/#/collection"><img src="../assets/img/icon-collection.png" alt="我的收藏" />我的收藏</a>
        <a href="/#/message"><img src="../assets/img/icon-message.png" alt="我的消息" />我的消息</a> -->
      </div>
      <div class="box">
        <router-link to="/set"><img src="../assets/img/icon-set.png" alt="系统设置" />系统设置</router-link>
        <router-link to="/service"><img src="../assets/img/icon-service.png" alt="客服咨询" />客服咨询</router-link>
        <router-link to="/skiPhone"><span>{{phoneNumber}}</span><img src="../assets/img/icon-member.png" alt="手机号绑定" />手机号绑定</router-link>
        <!--<router-link to="/phone" v-if="!phoneNumber || phoneNumber == ''"><span>{{phoneNumber}}</span><img src="../assets/img/icon-member.png" alt="手机号绑定" />手机号绑定</router-link>-->
        <!--<a href="javascript:;" v-else class="bound"><span>{{phoneNumber}}</span><img src="../assets/img/icon-member.png" alt="手机号绑定" />手机号绑定</a>-->
        <!-- <a href="/#/set"><img src="../assets/img/icon-set.png" alt="系统设置" />系统设置</a>
        <a href="/#/service"><img src="../assets/img/icon-service.png" alt="客服咨询" />客服咨询</a> -->
      </div>
    </div>
    <tabbar :num="4"></tabbar>
  </div>
</template>
<script>
  import tabbar from '../components/tabbar';
  export default {
    name: 'My',
    components:{
      tabbar
    },
    data () {
      return{
        urls: {
          userInfo: 'userInfo/findUserInfo' // 获取用户信息地址
        },
        avatarUrl: '', // 头像地址
        nickName: '', // 昵称
        phoneNumber: '' // 手机号码
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    mounted(){
      console.log('个人中心')
      this.getUserInfo()
      console.log(this.$global.token)
    },
    methods: {
      getUserInfo() {
        const me = this;
        this.$post({
          url: this.urls.userInfo,
          params:{
            token: this.$global.token,
            deviceId: ''
          }
        }).then(rsp => {
          console.log(rsp)
          me.avatarUrl = rsp.data.headPortrait
          me.nickName = rsp.data.nickname
          if(rsp.data.phoneNumber){
            me.phoneNumber = rsp.data.phoneNumber.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")
          }
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    background:  #F7F7F7;
    padding-bottom: 75px;
    box-sizing: border-box;
  }
  .userinfo{
    font-size: 0;
    width: 100%;
    padding: 1rem 20px 1.5rem 20px;
    background: url(../assets/img/bg-usercenter.png) no-repeat center center/cover;
    box-sizing: border-box;
    .avatar{
      display: inline-block;
      vertical-align: middle;
      img{
        width: 62px;
        height: 62px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #fff;
      }
    }
    .info{
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      text-align: left;
      width: calc(100% - 132px);
      padding: 0 10px;
      h1 {
        font-size: .5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      p {
        font-size: .4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    a{
      float: right;
      vertical-align: middle;
      width: 50px;
      font-size: .3rem;
      text-align: center;
      // display: inline-block;
      margin-top: 10px;
      text-decoration: none;
      color: #fff;
      img{
        width: .6rem;
      }
    }
  }
  .menu{
    padding: 0 20px;
    margin-top: -43px;
    .box{
      margin-top: 15px;
      border-radius: 5px;
      box-shadow:  0 0 5px #ccc;
      a{
        display: block;
        background: url(../assets/img/icon-more.png) no-repeat 97% center/auto 15px #fff;
        margin-top: 2px;
        text-align: left;
        padding: 10px;
        font-size: 16px;
        line-height: 32px;
        color: #141212;
        img{
          width: 32px;
          height: 32px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
        span{
          float: right;
          color: #999;
          margin-right: 5px;
        }
      }
      a:first-of-type{
        border-radius: 5px 5px 0 0;
      }
      a:last-of-type{
        border-radius: 0 0 5px 5px;
      }
      a.bound{
        background: #fff;
      }
    }
  }
</style>
